import { GetLatestStopEstimatesQuery } from '~/src/components/shared/features/__generated_types__/queries.gql'

import {
  StopAddRequest,
  StopDuplicateRequest,
  StopUpdate,
} from '~/__generated_types__/globalTypes'
import {
  GetAllCostCentersQuery,
  GetCostCenterFixedRoutesQuery,
  GetCostCenterLocationsQuery,
  GetCostCentersQuery,
  GetDriversQuery,
  GetLoadGeorouteQuery,
  GetLoadQuery,
  GetReferenceTypesQuery,
  GetLoadsQuery,
  GetLoadsWithReferenceQuery,
  GetMileageTypesQuery,
  GetOverlappingLegAssignmentsForTractorQuery,
  GetStopEventsForCostCenterQuery,
  GetTractorSearchQuery,
  UpdateStopMutation,
  ManualArriveDepartMutationVariables,
} from '~/src/components/Operational/LoadBoardDashboard/__generated_types__/queries.gql'

export type Load = GetLoadsQuery['getLoads']['results'][0]
export type Leg = Load['legs'][0]
export type DetailedLoad = Omit<GetLoadQuery['getLoad'], 'legs'> & {
  legs: Array<LegWithEstimates>
}
export type LoadWithScheduleEstimates = GetLatestStopEstimatesQuery['getLoad']
export type StopWithEstimate = LoadWithScheduleEstimates['legs'][0]['stops'][0]
export type UpdateStop = Extract<
  UpdateStopMutation['updateStop'],
  { __typename: 'Load' }
>
export type LegWithEstimates = Omit<GetLoadQuery['getLoad']['legs'][0], 'stops'> & {
  stops: Array<Stop>
}
export type Stop = GetLoadQuery['getLoad']['legs'][0]['stops'][0] & {
  globalIdx?: number
}
export type StopEstimate = Stop['estimateData']
export type Reference = GetLoadsWithReferenceQuery['getLoads']['results'][0]
export type CostCenter = GetCostCentersQuery['getCostCenters']['results'][0]
export type CostCenterWithCode = GetAllCostCentersQuery['getAllActiveCostCenters'][0]
export type LoadCostCenter = GetLoadQuery['getLoad']['costCenter']
export type Driver = GetDriversQuery['getDrivers']['results'][0]
export type StopLocation = GetCostCenterLocationsQuery['getLocations']['results'][0]
export type MileageType = GetMileageTypesQuery['getCostCenterMileageTypes'][0]
export type StopType =
  GetStopEventsForCostCenterQuery['getCostCenters']['results'][0]['stopTypes'][0]
export type Route =
  GetCostCenterFixedRoutesQuery['getCostCenters']['results'][0]['fixedRoutes'][0]
export type CostCenterWithRoutes =
  GetCostCenterFixedRoutesQuery['getCostCenters']['results'][0]
export type RouteStop = Route['stops'][0]
export type StopActionWithName =
  GetStopEventsForCostCenterQuery['getCostCenters']['results'][0]['allowedStopEvents'][0]
export type LoadReference = Omit<
  GetLoadQuery['getLoad']['customerReferenceNumbers'][0],
  '__typename'
> & {
  stopId?: number | null
}

export type Tractor = GetTractorSearchQuery['getTractors']['results'][0]
export type OverlappingLoadsForTractor =
  GetOverlappingLegAssignmentsForTractorQuery['getTractors']['results'][0]['overlappingLoadAssignments']

export type LoadMetaData = {
  currentPage: number
  totalPages: number
}

export type DateRange = {
  start: string
  end: string
}

export type TrackingData = {
  legIdx: number
}

export enum StopStatus {
  Completed = 'CMP',
  AtLocation = 'LOC',
  InTransit = 'TRN',
  Planned = 'PLN',
  Cancelled = 'CAN',
}

export type ReOrderableStop = Stop & { isReorderable?: boolean; legId: number }

export enum FieldType {
  CostCenter = 'COST_CENTER',
  FixedRoute = 'FIXED_ROUTE',
}

export interface PDFPreview {
  url: string
  title: string
}

export type StopUpdateBase = Omit<StopUpdate, 'tmwVersionId'>
export type StopBase = Omit<StopAddRequest, 'tmwVersionId' | 'idx' | 'legId'>
export type StopAddBase = Omit<StopAddRequest, 'tmwVersionId'>
export type StopDuplicateBase = Omit<StopDuplicateRequest, 'tmwVersionId'>
export type ReferenceType = GetReferenceTypesQuery['getReferenceTypes'][0]

export type GeorouteLeg = GetLoadGeorouteQuery['getLoad']['legs'][0]

export type ManualArriveDepartStops = ManualArriveDepartMutationVariables['input']
